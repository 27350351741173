import React from 'react';
import { Link } from 'react-router-dom';
import { FileText, Mail, MessageSquare } from 'react-feather';

const AIComposePage: React.FC = () => {
  const composeTools = [
    { name: 'Document Composer', icon: FileText, path: '/composer/document' },
    { name: 'Email Composer', icon: Mail, path: '/composer/email' },
    { name: 'Social Post Composer', icon: MessageSquare, path: '/composer/social' },
    { name: 'Slack Message Composer', icon: MessageSquare, path: '/composer/slack' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">AI Composer</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {composeTools.map((tool) => (
          <Link
            key={tool.name}
            to={tool.path}
            className="bg-white/10 p-6 rounded-lg hover:bg-white/20 transition-colors duration-200 flex flex-col"
          >
            <div className="flex items-center mb-4">
              <tool.icon size={24} className="mr-2 text-xylo-pink" />
              <h2 className="text-xl font-semibold">{tool.name}</h2>
            </div>
            <p className="text-gray-300 flex-grow">Create AI-powered {tool.name.toLowerCase()}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AIComposePage;