import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, Clock, Plus, X, Calendar } from 'react-feather';
import TaskEditModal from './TaskEditModal';

interface Task {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  isCompleted: boolean;
  category: 'urgent-important' | 'not-urgent-important' | 'urgent-not-important' | 'not-urgent-not-important';
}

interface RecommendedTask {
  id: string;
  title: string;
  emailSubject: string;
  emailSender: string;
  category: Task['category'];
}

const TasksPage: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [recommendedTasks, setRecommendedTasks] = useState<RecommendedTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetchTasks();
    fetchRecommendedTasks();
  }, []);

  const fetchTasks = async () => {
    // TODO: Implement API call to fetch tasks
    const mockTasks: Task[] = [
      { id: '1', title: 'Review Q3 report', description: 'Analyze and summarize Q3 performance', dueDate: '2024-08-07', isCompleted: false, category: 'urgent-important' },
      { id: '2', title: 'Prepare presentation', description: 'Create slides for the board meeting', dueDate: '2024-08-08', isCompleted: false, category: 'not-urgent-important' },
      { id: '3', title: 'Client meeting', description: 'Discuss project progress with the client', dueDate: '2024-08-09', isCompleted: false, category: 'urgent-important' },
      { id: '4', title: 'Team performance reviews', description: 'Conduct annual performance evaluations', dueDate: '2024-08-12', isCompleted: false, category: 'not-urgent-important' },
      { id: '5', title: 'Project kickoff meeting', description: 'Initiate new project with the team', dueDate: '2024-08-14', isCompleted: false, category: 'urgent-not-important' },
    ];
    setTasks(mockTasks);
  };

  const fetchRecommendedTasks = async () => {
    // TODO: Implement API call to fetch AI-recommended tasks
    const mockRecommendedTasks: RecommendedTask[] = [
      { id: 'r1', title: 'Follow up with John', emailSubject: 'Project Update', emailSender: 'john@example.com', category: 'urgent-important' },
      { id: 'r2', title: 'Schedule team meeting', emailSubject: 'Team Sync', emailSender: 'manager@example.com', category: 'not-urgent-important' },
      { id: 'r3', title: 'Review budget proposal', emailSubject: 'Q4 Budget', emailSender: 'finance@example.com', category: 'urgent-not-important' },
      { id: 'r4', title: 'Prepare monthly report', emailSubject: 'Monthly Metrics', emailSender: 'analytics@example.com', category: 'not-urgent-not-important' },
      { id: 'r5', title: 'Respond to client inquiry', emailSubject: 'Product Question', emailSender: 'client@example.com', category: 'urgent-important' },
    ];
    setRecommendedTasks(mockRecommendedTasks);
  };

  const addToTasks = (task: RecommendedTask) => {
    const newTask: Task = {
      id: `new-${task.id}`,
      title: task.title,
      description: `Created from email: ${task.emailSubject}`,
      dueDate: new Date().toISOString().split('T')[0], // Set to today's date
      isCompleted: false,
      category: task.category,
    };
    setTasks([...tasks, newTask]);
    setRecommendedTasks(recommendedTasks.filter(t => t.id !== task.id));
    // TODO: Implement API call to add task
  };

  const removeRecommendedTask = (taskId: string) => {
    setRecommendedTasks(recommendedTasks.filter(task => task.id !== taskId));
  };

  const toggleTaskCompletion = (taskId: string) => {
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, isCompleted: !task.isCompleted } : task
    ));
    // TODO: Implement API call to update task status
  };

  const openEditModal = (task: Task) => {
    setSelectedTask(task);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedTask(null);
    setIsEditModalOpen(false);
  };

  const updateTask = (updatedTask: Task) => {
    setTasks(tasks.map(task => task.id === updatedTask.id ? updatedTask : task));
    closeEditModal();
    // TODO: Implement API call to update task
  };

  const categorizeTasksByEisenhower = (tasks: Task[]) => {
    return {
      'urgent-important': tasks.filter(task => task.category === 'urgent-important'),
      'not-urgent-important': tasks.filter(task => task.category === 'not-urgent-important'),
      'urgent-not-important': tasks.filter(task => task.category === 'urgent-not-important'),
      'not-urgent-not-important': tasks.filter(task => task.category === 'not-urgent-not-important'),
    };
  };

  const categorizedTasks = categorizeTasksByEisenhower(tasks);

  const getCategoryColor = (category: Task['category']): string => {
    switch (category) {
      case 'urgent-important':
        return 'bg-red-500';
      case 'not-urgent-important':
        return 'bg-yellow-500';
      case 'urgent-not-important':
        return 'bg-blue-500';
      case 'not-urgent-not-important':
        return 'bg-green-500';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Tasks</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/10 p-6 rounded-lg"
        >
          <h2 className="text-2xl font-semibold mb-4">AI Recommended Tasks</h2>
          {recommendedTasks.length > 0 ? (
            <ul className="space-y-4">
              {recommendedTasks.map(task => (
                <li key={task.id} className="flex items-center justify-between bg-white/5 p-4 rounded-lg">
                  <div>
                    <h3 className="font-medium">{task.title}</h3>
                    <p className="text-sm text-gray-400">From: {task.emailSender}</p>
                    <p className="text-sm text-gray-400">Subject: {task.emailSubject}</p>
                    <p className={`text-sm font-medium ${getCategoryColor(task.category)}`}>
                      <Calendar size={14} className="inline mr-1" />
                      {task.category.replace('-', ' ')}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-xylo-pink text-white p-2 rounded-full"
                      onClick={() => addToTasks(task)}
                    >
                      <Plus size={20} />
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-gray-600 text-white p-2 rounded-full"
                      onClick={() => removeRecommendedTask(task.id)}
                    >
                      <X size={20} />
                    </motion.button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-400">No recommended tasks at the moment.</p>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white/10 p-6 rounded-lg"
        >
          <h2 className="text-2xl font-semibold mb-4">Tasks</h2>
          {Object.entries(categorizedTasks).map(([category, tasks]) => (
            <div key={category} className="mb-6">
              <h3 className="text-xl font-medium mb-3 capitalize">{category.replace('-', ' ')}</h3>
              {tasks.length > 0 ? (
                <ul className="space-y-3">
                  {tasks.map(task => (
                    <li 
                      key={task.id} 
                      className="flex items-center justify-between bg-white/5 p-3 rounded-lg cursor-pointer hover:bg-white/10"
                      onClick={() => openEditModal(task)}
                    >
                      <div className="flex items-center">
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          className={`mr-3 ${task.isCompleted ? 'text-green-500' : 'text-gray-400'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleTaskCompletion(task.id);
                          }}
                        >
                          <CheckCircle size={20} />
                        </motion.button>
                        <span className={task.isCompleted ? 'line-through text-gray-400' : ''}>
                          {task.title}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className={`w-3 h-3 rounded-full mr-2 ${getCategoryColor(task.category)}`}></div>
                        <div className="text-sm text-gray-400">
                          <Clock size={16} className="inline mr-1" />
                          {task.dueDate}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-400">No tasks in this category.</p>
              )}
            </div>
          ))}
        </motion.div>
      </div>

      <TaskEditModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        task={selectedTask}
        onUpdate={updateTask}
      />
    </div>
  );
};

export default TasksPage;