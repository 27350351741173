import { PGlite } from "@electric-sql/pglite";

let db: PGlite;

export const initDatabase = async () => {
  try {
    // Initialize the database
    db = new PGlite("./xylo_local_db");

    // Execute your SQL commands to create tables
    await db.query(`
      -- Create Users Table
      CREATE TABLE IF NOT EXISTS users (
        id TEXT PRIMARY KEY,
        first_name TEXT NOT NULL,
        last_name TEXT NOT NULL,
        email TEXT UNIQUE NOT NULL,
        signature_block TEXT,
        timezone TEXT DEFAULT 'UTC',
        language TEXT DEFAULT 'en',
        theme TEXT DEFAULT 'light',
        notification_preferences JSONB DEFAULT '{}'
      );

      -- Create Emails Table
      CREATE TABLE IF NOT EXISTS emails (
        id TEXT PRIMARY KEY,
        user_id TEXT NOT NULL,
        sender TEXT NOT NULL,
        recipient TEXT NOT NULL,
        subject TEXT NOT NULL,
        body TEXT NOT NULL,
        sent_at TIMESTAMP WITH TIME ZONE DEFAULT CURRENT_TIMESTAMP,
        is_read BOOLEAN DEFAULT FALSE,
        thread_id TEXT,
        parent_email_id TEXT,
        is_important BOOLEAN DEFAULT FALSE,
        is_archived BOOLEAN DEFAULT FALSE,
        snooze_until TIMESTAMP WITH TIME ZONE,
        is_junk BOOLEAN DEFAULT FALSE,
        created_at TIMESTAMP WITH TIME ZONE,
        last_modified_at TIMESTAMP WITH TIME ZONE,
        change_key TEXT,
        received_at TIMESTAMP WITH TIME ZONE,
        has_attachments BOOLEAN,
        internet_message_id TEXT,
        importance TEXT,
        conversation_index TEXT,
        is_delivery_receipt_requested BOOLEAN,
        is_read_receipt_requested BOOLEAN,
        is_draft BOOLEAN,
        web_link TEXT,
        inference_classification TEXT,
        body_preview TEXT,
        body_content_type TEXT,
        FOREIGN KEY (user_id) REFERENCES users (id)
      );
    `);

    console.log('Database initialized successfully');
  } catch (error) {
    console.error('Error initializing database:', error);
  }
};

export { db };