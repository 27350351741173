import React, { createContext, useContext, useState, useCallback } from "react";
import {
  fetchUserId,
  fetchUserDetails,
  fetchOrganization,
  fetchOrganizationUsers,
  fetchOrganizationTeams,
  fetchInviteList,
  fetchLicenses,
} from "./api";
import { handleAuthRedirect } from "../page_components/auth/microsoft-auth";
import { initDatabase, dbService } from '../db/dbService';

interface StateManagerContextType {
  state: {
    userId: null | string | void;
    organizationId: any;
    users: any;
    setUsers: any;
    editUserModelOpen: any;
    setEditUserModelOpen: any;
    editUserDetails: any;
    setEditUserDetails: any;
    editUserEmail: any;
    setEditUserEmail: any;
    organization: any;
    setOrganization: any;
    modelOpen: any;
    setModelOpen: any;
    userDetails: any;
    deleteModelOpen: any;
    setDeleteModelOpen: any;
    isSameClickedUser: any;
    setIsSameClickedUser: any;
    organizationTeams: any;
    archivedUsers: any;
    setArchivedUsers: any;
    invitedUsers: any;
    setInvitedUsers: any;
    tenantId: string | null;
    licenses: any;
    refreshOrganizationUsers: any;
    setLicenses: any;
    isSwitchLoading: any;
    setIsSwitchLoading: any;
  };
  refreshOrganizationUsers: () => void;
}

const StateManagerContext = createContext<StateManagerContextType | undefined>(
  undefined
);

export const StateManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<null | string | void>(null);
  const [userDetails, setUserDetails] = useState<null | string | void>(null);
  const [organizationId, setOrganizationId] = useState<any>(null);
  const [organization, setOrganization] = useState(null);
  const [organizationTeams, setOrganizationTeams] = useState(null);
  const [users, setUsers] = useState<any>(null);
  const [editUserModelOpen, setEditUserModelOpen] = useState(null);
  const [editUserDetails, setEditUserDetails] = useState(null);
  const [editUserEmail, setEditUserEmail] = useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isSameClickedUser, setIsSameClickedUser] = useState(false);
  const [archivedUsers, setArchivedUsers] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [licenses, setLicenses] = useState<any>(null);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);

  const refreshOrganizationUsers = useCallback(async () => {
    if (organizationId) {
      setUsers(null);
      setInvitedUsers(null);

      const organizationUsers = await fetchOrganizationUsers(organizationId);

      const activeUsers = organizationUsers.filter(
        (user: any) => user.active === true
      );

      const archivedUsers = organizationUsers.filter(
        (user: any) => user.active === false
      );

      setUsers(activeUsers);
      setArchivedUsers(archivedUsers);
      const invitedUsers = await fetchInviteList(organizationId);
      setInvitedUsers(invitedUsers);
    }
  }, [organizationId]);

  const [initialLoad, setInitialLoad] = useState(true);

  React.useEffect(() => {
    const call = async () => {
      try {
        await initDatabase(); // Initialize the database
        const userId = await fetchUserId();

        if (userId) {
          await fetchData(userId);
        } else {
          try {
            const data = await handleAuthRedirect();
            if (!data) {
              console.log("Auth redirect failed");
              // Handle failed auth redirect
            }
            const userIdAfterRedirect = await fetchUserId();
            if (userIdAfterRedirect) {
              await fetchData(userIdAfterRedirect);
            } else {
              console.log("Failed to get user ID after redirect");
              // Handle failed user ID fetch
            }
          } catch (err) {
            console.error("Error during auth redirect:", err);
            // Handle auth redirect error
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setInitialLoad(false);
      }
    };

    const fetchData = async (userId: any) => {
      const userDetails = await fetchUserDetails(userId.user_id);
      setUserId(userId.user_id);
      setUserDetails(userDetails);
      
      // Add user to local database
      await dbService.addUser({
        id: userId.user_id,
        first_name: userDetails.given_name,
        last_name: userDetails.surname,
        email: userDetails.mail || userDetails.userPrincipalName,
      });

      setOrganizationId(userDetails.organizations[0].organization);
      const organization = await fetchOrganization(
        userDetails.organizations[0].organization
      );
      setOrganization(organization);
      setTenantId(organization.tenant_id);
      const organizationTeams = await fetchOrganizationTeams(
        userDetails.organizations[0].organization
      );
      setOrganizationTeams(organizationTeams);
      const licenses = await fetchLicenses(organization.tenant_id);
      setLicenses(licenses);
      await refreshOrganizationUsers();
    };

    if (initialLoad) {
      call();
    }
  }, [refreshOrganizationUsers, initialLoad]);

  const state = {
    userId,
    organizationId,
    users,
    editUserModelOpen,
    setEditUserModelOpen,
    editUserDetails,
    setEditUserDetails,
    editUserEmail,
    setEditUserEmail,
    organization,
    setOrganization,
    modelOpen,
    setModelOpen,
    userDetails,
    deleteModelOpen,
    setDeleteModelOpen,
    isSameClickedUser,
    setIsSameClickedUser,
    organizationTeams,
    setUsers,
    archivedUsers,
    setArchivedUsers,
    invitedUsers,
    setInvitedUsers,
    tenantId,
    licenses,
    refreshOrganizationUsers,
    setLicenses,
    isSwitchLoading,
    setIsSwitchLoading,
  };

  return (
    <StateManagerContext.Provider value={{ state, refreshOrganizationUsers }}>
      {children}
    </StateManagerContext.Provider>
  );
};

export const useStateManager = () => {
  const context = useContext(StateManagerContext);
  if (!context) {
    throw new Error(
      "useStateManager must be used within a StateManagerProvider"
    );
  }
  return context;
};