import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, User, Smile } from 'react-feather';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface Contact {
  id: string;
  name: string;
  email: string;
  company: string;
  lastInteraction: string;
  interactionFrequency: number;
  relationshipStrength: number;
  personalityTraits: {
    openness: number;
    conscientiousness: number;
    extraversion: number;
    agreeableness: number;
    neuroticism: number;
  };
  communicationStyle: {
    askVsTell: number;
    emotiveVsControlling: number;
  };
  communicationTips: string[];
}

interface Interaction {
  id: string;
  date: string;
  type: string;
  sentiment: number;
  summary: string;
}

const mockContacts: Contact[] = [
  {
    id: '1',
    name: 'John Doe',
    email: 'john@example.com',
    company: 'ABC Corp',
    lastInteraction: '2023-07-10',
    interactionFrequency: 0.8,
    relationshipStrength: 0.9,
    personalityTraits: {
      openness: 0.7,
      conscientiousness: 0.8,
      extraversion: 0.6,
      agreeableness: 0.9,
      neuroticism: 0.3,
    },
    communicationStyle: {
      askVsTell: 0.4,
      emotiveVsControlling: 0.3,
    },
    communicationTips: [
      "Begin on a positive note to set a friendly tone",
      "Show sincere appreciation for John's insights",
      "Ask questions to demonstrate curiosity about his projects"
    ]
  },
  {
    id: '2',
    name: 'Jane Smith',
    email: 'jane@example.com',
    company: 'XYZ Inc',
    lastInteraction: '2023-07-08',
    interactionFrequency: 0.6,
    relationshipStrength: 0.7,
    personalityTraits: {
      openness: 0.9,
      conscientiousness: 0.7,
      extraversion: 0.8,
      agreeableness: 0.6,
      neuroticism: 0.4,
    },
    communicationStyle: {
      askVsTell: 0.7,
      emotiveVsControlling: 0.6,
    },
    communicationTips: [
      "Be more direct in your communication style",
      "Express empathy and understanding for her concerns",
      "Follow up promptly to maintain momentum in discussions"
    ]
  },
  {
    id: '3',
    name: 'Alice Johnson',
    email: 'alice@example.com',
    company: 'Tech Innovators',
    lastInteraction: '2023-07-12',
    interactionFrequency: 0.5,
    relationshipStrength: 0.6,
    personalityTraits: {
      openness: 0.8,
      conscientiousness: 0.9,
      extraversion: 0.4,
      agreeableness: 0.7,
      neuroticism: 0.5,
    },
    communicationStyle: {
      askVsTell: 0.3,
      emotiveVsControlling: 0.4,
    },
    communicationTips: [
      "Be more verbose in your explanations",
      "Avoid arguing or criticizing, focus on constructive feedback",
      "Add value by sharing relevant resources or articles"
    ]
  },
  {
    id: '4',
    name: 'Bob Williams',
    email: 'bob@example.com',
    company: 'Global Solutions',
    lastInteraction: '2023-07-09',
    interactionFrequency: 0.7,
    relationshipStrength: 0.8,
    personalityTraits: {
      openness: 0.6,
      conscientiousness: 0.8,
      extraversion: 0.7,
      agreeableness: 0.8,
      neuroticism: 0.3,
    },
    communicationStyle: {
      askVsTell: 0.6,
      emotiveVsControlling: 0.5,
    },
    communicationTips: [
      "Demonstrate active listening by referencing specific points from previous conversations",
      "Personalize your messages to show you value your interactions",
      "Be genuine and sincere in your communications"
    ]
  },
];

const mockInteractions: Interaction[] = [
  { id: '1', date: '2023-07-10', type: 'Email', sentiment: 0.8, summary: 'Discussed project timeline' },
  { id: '2', date: '2023-07-08', type: 'Call', sentiment: 0.6, summary: 'Reviewed quarterly report' },
  { id: '3', date: '2023-07-12', type: 'Meeting', sentiment: 0.9, summary: 'Brainstormed new product ideas' },
  { id: '4', date: '2023-07-09', type: 'Email', sentiment: 0.7, summary: 'Followed up on action items' },
];

const RelationshipPage: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>(mockContacts);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (selectedContact) {
      // In a real app, you would fetch interactions for the selected contact
      setInteractions(mockInteractions);
    }
  }, [selectedContact]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    const filtered = mockContacts.filter(contact =>
      contact.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      contact.email.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setContacts(filtered);
  };

  const handleContactSelect = (contact: Contact) => {
    setSelectedContact(contact);
  };

  const renderRelationshipStrength = (strength: number) => {
    const percentage = strength * 100;
    return (
      <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="absolute top-0 left-0 h-full bg-xylo-pink"
          style={{ width: `${percentage}%` }}
        />
        <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
          {percentage.toFixed(0)}%
        </span>
      </div>
    );
  };

  const renderPersonalityTraits = (traits: Contact['personalityTraits']) => {
    const traitNames = ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'];
    return (
      <div className="grid grid-cols-2 gap-4">
        {Object.entries(traits).map(([trait, value], index) => (
          <div key={trait} className="flex flex-col">
            <span className="text-sm text-gray-300">{traitNames[index]}</span>
            <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
              <div
                className="absolute top-0 left-0 h-full bg-xylo-pink"
                style={{ width: `${value * 100}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCommunicationStyle = (style: Contact['communicationStyle']) => {
    return (
      <div className="space-y-4">
        <div>
          <div className="flex justify-between text-sm text-gray-300">
            <span>Ask</span>
            <span>Tell</span>
          </div>
          <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full bg-xylo-pink"
              style={{ width: `${style.askVsTell * 100}%` }}
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between text-sm text-gray-300">
            <span>Emotive</span>
            <span>Controlling</span>
          </div>
          <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full bg-xylo-pink"
              style={{ width: `${style.emotiveVsControlling * 100}%` }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInteractionHistory = () => {
    const data = interactions.map(interaction => ({
      date: interaction.date,
      sentiment: interaction.sentiment * 100,
    }));

    return (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="sentiment" stroke="#ED5C5E" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Relationship Intelligence</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-1 bg-white/10 p-6 rounded-lg">
          <div className="mb-4 relative">
            <input
              type="text"
              placeholder="Search contacts..."
              className="w-full bg-white/20 text-white rounded-full py-2 px-4 pl-10"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto">
            {contacts.map(contact => (
              <motion.div
                key={contact.id}
                className={`p-4 rounded-lg cursor-pointer ${selectedContact?.id === contact.id ? 'bg-xylo-pink' : 'bg-white/5 hover:bg-white/10'}`}
                onClick={() => handleContactSelect(contact)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <h3 className="font-semibold">{contact.name}</h3>
                <p className="text-sm text-gray-300">{contact.email}</p>
                <p className="text-sm text-gray-400">{contact.company}</p>
              </motion.div>
            ))}
          </div>
        </div>
        
        <div className="lg:col-span-2 space-y-6">
          {selectedContact ? (
            <>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Contact Overview</h2>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-400">Name</p>
                    <p className="font-semibold">{selectedContact.name}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Email</p>
                    <p className="font-semibold">{selectedContact.email}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Company</p>
                    <p className="font-semibold">{selectedContact.company}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Last Interaction</p>
                    <p className="font-semibold">{selectedContact.lastInteraction}</p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Relationship Strength</h2>
                {renderRelationshipStrength(selectedContact.relationshipStrength)}
                <p className="mt-2 text-sm text-gray-300">
                  Based on interaction frequency, sentiment, and engagement
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Personality Traits</h2>
                {renderPersonalityTraits(selectedContact.personalityTraits)}
                <p className="mt-2 text-sm text-gray-300">
                  Based on the Big Five personality model
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Communication Style</h2>
                {renderCommunicationStyle(selectedContact.communicationStyle)}
                <p className="mt-2 text-sm text-gray-300">
                  Based on analysis of past interactions
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Interaction History</h2>
                {renderInteractionHistory()}
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Communication Insights</h2>
                <div className="space-y-4">
                  {selectedContact.communicationTips.map((tip, index) => (
                    <div key={index} className="flex items-start">
                      <Smile className="text-xylo-pink mr-2 mt-1" size={20} />
                      <p className="text-sm text-gray-300">{tip}</p>
                    </div>
                  ))}
                </div>
              </motion.div>
            </>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-400">
              <User size={48} className="mr-4" />
              <p>Select a contact to view relationship details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelationshipPage;