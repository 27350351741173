import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Mail, AlertCircle, Plus, Clock, Database } from 'react-feather';
import { useStateManager } from './handlers/statemanager';
import { dbService } from './db/dbService';

interface UnreadEmails {
  important: number;
  other: number;
}

interface ActionItem {
  text: string;
  importance: 'high' | 'new';
}

interface SuggestedMeeting {
  title: string;
  time: string;
}

interface MetricCardProps {
  title: string;
  value: string;
  subtext: string;
}

const Dashboard: React.FC = () => {
  const { state } = useStateManager();
  const [localUserData, setLocalUserData] = useState<any>(null);
  const [dbStatus, setDbStatus] = useState<'working' | 'error' | 'loading'>('loading');

  useEffect(() => {
    const fetchLocalUserData = async () => {
      if (state.userId) {
        try {
          const userData = await dbService.getUserById(state.userId);
          setLocalUserData(userData);
          setDbStatus('working');
        } catch (error) {
          console.error('Error fetching local user data:', error);
          setDbStatus('error');
        }
      }
    };

    fetchLocalUserData();
  }, [state.userId]);

  // Mock data
  const unreadEmails: UnreadEmails = {
    important: 12,
    other: 43
  };

  const actionItems: ActionItem[] = [
    { text: "Review Q3 Financial Report", importance: "high" },
    { text: "Prepare for TechCorp partnership meeting", importance: "high" },
    { text: "Follow up on Project Phoenix timeline", importance: "high" },
    { text: "Review new marketing strategy proposal", importance: "new" },
    { text: "Schedule team building event", importance: "new" },
  ];

  const suggestedMeetings: SuggestedMeeting[] = [
    { title: "Weekly Team Sync", time: "10:00 AM" },
    { title: "Client Presentation: HealthTech Solutions", time: "2:00 PM" },
    { title: "1:1 with Sarah Johnson", time: "4:30 PM" },
  ];

  const MetricCard: React.FC<MetricCardProps> = ({ title, value, subtext }) => (
    <div className="bg-white/10 p-4 rounded-lg">
      <h3 className="text-lg font-medium text-white mb-2">{title}</h3>
      <div className="text-2xl font-bold text-xylo-pink">{value}</div>
      <div className="text-sm text-gray-300 mt-1">{subtext}</div>
    </div>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* User Information */}
      <motion.div 
        className="lg:col-span-3 bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Welcome, {localUserData?.first_name || 'User'}</h2>
        <div className="flex items-center space-x-4">
          <Database size={24} className={dbStatus === 'working' ? 'text-green-500' : 'text-red-500'} />
          <span className="text-white">
            Local Database Status: {dbStatus === 'working' ? 'Connected' : dbStatus === 'error' ? 'Error' : 'Connecting...'}
          </span>
        </div>
        {localUserData && (
          <div className="mt-4 text-white">
            <p>Email: {localUserData.email}</p>
            <p>Language: {localUserData.language}</p>
            <p>Theme: {localUserData.theme}</p>
          </div>
        )}
      </motion.div>

      {/* Communication Metrics */}
      <motion.div 
        className="lg:col-span-2 bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Communication Metrics</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <MetricCard title="Clarity Score" value="85 / 100" subtext="Messages are clear and concise" />
          <MetricCard title="Time Saved This Week" value="3.5 hours" subtext="Optimized workflow" />
          <MetricCard title="Communication Friction" value="28% reduced" subtext="Fewer misunderstandings" />
        </div>
      </motion.div>

      {/* Today's Unread Emails */}
      <motion.div 
        className="bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Today's Unread - Emails</h2>
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span className="text-lg text-white">Important:</span> 
            <span className="text-2xl font-bold text-xylo-pink">{unreadEmails.important}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-lg text-white">Other:</span> 
            <span className="text-2xl font-bold text-xylo-pink">{unreadEmails.other}</span>
          </div>
        </div>
        <Link to="/emails" className="mt-4 inline-flex items-center bg-xylo-purple text-white px-4 py-2 rounded-full hover:bg-opacity-80 transition-colors">
          <Mail size={18} className="mr-2" />
          View Emails
        </Link>
      </motion.div>

      {/* Today's Action List */}
      <motion.div 
        className="lg:col-span-2 bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Today's Action List</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-white mb-2">High Importance</h3>
            <ul className="space-y-2">
              {actionItems.filter(item => item.importance === "high").map((item, index) => (
                <li key={index} className="flex items-center space-x-2 text-white">
                  <AlertCircle size={18} className="text-red-500" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-medium text-white mb-2">New to Review</h3>
            <ul className="space-y-2">
              {actionItems.filter(item => item.importance === "new").map((item, index) => (
                <li key={index} className="flex items-center space-x-2 text-white">
                  <Plus size={18} className="text-yellow-500" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Link to="/tasks" className="mt-4 inline-flex items-center bg-xylo-purple text-white px-4 py-2 rounded-full hover:bg-opacity-80 transition-colors">
          <AlertCircle size={18} className="mr-2" />
          View All Tasks
        </Link>
      </motion.div>

      {/* Suggested Meetings */}
      <motion.div 
        className="bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Suggested Meetings</h2>
        <ul className="space-y-3">
          {suggestedMeetings.map((meeting, index) => (
            <li key={index} className="flex items-center justify-between text-white bg-white/10 p-3 rounded-lg">
              <span>{meeting.title}</span>
              <span className="text-xylo-pink flex items-center">
                <Clock size={16} className="mr-1" />
                {meeting.time}
              </span>
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Dashboard;