import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, FileText, MessageSquare, File } from 'react-feather';

const TemplatePage: React.FC = () => {
  const templateTypes = [
    { name: 'Email Templates', icon: Mail, path: '/templates/email' },
    { name: 'Social Post Templates', icon: FileText, path: '/templates/social' },
    { name: 'Slack Message Templates', icon: MessageSquare, path: '/templates/slack' },
    { name: 'Document Templates', icon: File, path: '/templates/document' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-4 sm:p-8">
      <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-8">Template Library</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        {templateTypes.map((type) => (
          <Link
            key={type.name}
            to={type.path}
            className="bg-white/10 p-4 sm:p-6 rounded-lg hover:bg-white/20 transition-colors duration-200 flex flex-col"
          >
            <div className="flex items-center mb-4">
              <type.icon size={24} className="mr-2 text-xylo-pink" />
              <h2 className="text-lg sm:text-xl font-semibold">{type.name}</h2>
            </div>
            <p className="text-gray-300 flex-grow text-sm sm:text-base">Manage your {type.name.toLowerCase()}</p>
          </Link>
        ))}
      </div>
      <div className="mt-6 sm:mt-8">
        <Link
          to="/templates/phrases"
          className="bg-xylo-pink text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-medium inline-flex items-center hover:bg-opacity-80 transition-colors duration-200"
        >
          <FileText size={20} className="mr-2" /> Manage Phrases
        </Link>
      </div>
    </div>
  );
};

export default TemplatePage;