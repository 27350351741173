import React from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  children: React.ReactNode;
  show: boolean;
  position: { x: number; y: number };
}

const Tooltip: React.FC<TooltipProps> = ({ children, show, position }) => {
  if (!show) return null;
  
  return createPortal(
    <div 
      className="fixed z-50 px-2 py-1 bg-xylo-purple text-white text-sm rounded-md whitespace-nowrap"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translateY(-50%)',
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default Tooltip;