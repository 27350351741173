import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Plus, Edit2, Trash2, Search, Share2, ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

interface EmailTemplate {
  id: number;
  name: string;
  description: string;
  shareWithTeam: boolean;
  type: 'New' | 'Reply';
  referenceInformation: string;
  prompt: string;
}

const initialTemplates: EmailTemplate[] = [
  {
    id: 1,
    name: 'Product Introduction Email',
    description: 'Email template to introduce our product to potential clients',
    shareWithTeam: true,
    type: 'New',
    referenceInformation: 'Our product is a cutting-edge AI-powered communication assistant...',
    prompt: 'Write an email introducing our product to a potential client. Highlight its key features and benefits.',
  },
  {
    id: 2,
    name: 'Maintenance Issue Reply',
    description: 'Email template to respond to maintenance issues',
    shareWithTeam: true,
    type: 'Reply',
    referenceInformation: 'Standard maintenance procedures and contact information',
    prompt: 'Write a reply addressing the maintenance issue raised by the client. Provide clear next steps and contact information. Analyze the email thread prior to writing the reply.',
  },
];

const EmailTemplatePage: React.FC = () => {
  const [templates, setTemplates] = useState<EmailTemplate[]>(initialTemplates);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateTemplate = () => {
    const newTemplate: EmailTemplate = {
      id: templates.length + 1,
      name: 'New Email Template',
      description: 'Enter template description',
      shareWithTeam: false,
      type: 'New',
      referenceInformation: '',
      prompt: '',
    };
    setTemplates([...templates, newTemplate]);
    setSelectedTemplate(newTemplate);
    setIsEditing(true);
  };

  const handleEditTemplate = (template: EmailTemplate) => {
    setSelectedTemplate(template);
    setIsEditing(true);
  };

  const handleSaveTemplate = (editedTemplate: EmailTemplate) => {
    setTemplates(templates.map(t => t.id === editedTemplate.id ? editedTemplate : t));
    setSelectedTemplate(editedTemplate);
    setIsEditing(false);
  };

  const handleDeleteTemplate = (templateId: number) => {
    setTemplates(templates.filter(t => t.id !== templateId));
    setSelectedTemplate(null);
  };

  const handleBack = () => {
    navigate('/templates');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <div className="flex items-center mb-8">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="mr-4 p-2 bg-xylo-purple rounded-full"
          onClick={handleBack}
        >
          <ArrowLeft size={24} />
        </motion.button>
        <h1 className="text-4xl font-bold">Email Templates</h1>
      </div>
      
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-64">
          <input
            type="text"
            placeholder="Search templates..."
            className="w-full bg-white/10 p-2 rounded-lg pl-10"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <motion.button 
          className="bg-xylo-purple text-white px-4 py-2 rounded-lg flex items-center"
          onClick={handleCreateTemplate}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Plus size={20} className="mr-2" /> Create New Template
        </motion.button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredTemplates.map(template => (
          <motion.div
            key={template.id}
            className="bg-white/10 p-6 rounded-lg cursor-pointer"
            onClick={() => handleEditTemplate(template)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <h3 className="font-semibold text-lg mb-2">{template.name}</h3>
            <p className="text-sm text-gray-300 mb-4">{template.description}</p>
            <div className="flex justify-between items-center">
              <span className="text-xs bg-xylo-pink px-2 py-1 rounded-full">{template.type}</span>
              <div className="flex space-x-2">
                {template.shareWithTeam && <Share2 size={18} className="text-xylo-pink" />}
                <button className="text-xylo-pink hover:text-xylo-pink-light" onClick={(e) => { e.stopPropagation(); handleEditTemplate(template); }}>
                  <Edit2 size={18} />
                </button>
                <button className="text-red-500 hover:text-red-400" onClick={(e) => { e.stopPropagation(); handleDeleteTemplate(template.id); }}>
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {selectedTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-dark-surface p-8 rounded-lg w-full max-w-2xl"
          >
            <h2 className="text-2xl font-bold mb-6">{isEditing ? 'Edit Template' : 'Template Details'}</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleSaveTemplate(selectedTemplate); }}>
              <div className="space-y-4">
                <input
                  type="text"
                  value={selectedTemplate.name}
                  onChange={(e) => setSelectedTemplate({...selectedTemplate, name: e.target.value})}
                  className="w-full bg-white/10 p-2 rounded-lg"
                  placeholder="Template Name"
                  disabled={!isEditing}
                />
                <textarea
                  value={selectedTemplate.description}
                  onChange={(e) => setSelectedTemplate({...selectedTemplate, description: e.target.value})}
                  className="w-full bg-white/10 p-2 rounded-lg h-20"
                  placeholder="Template Description"
                  disabled={!isEditing}
                />
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedTemplate.shareWithTeam}
                    onChange={(e) => setSelectedTemplate({...selectedTemplate, shareWithTeam: e.target.checked})}
                    className="mr-2"
                    disabled={!isEditing}
                  />
                  <label>Share with team</label>
                </div>
                <select
                  value={selectedTemplate.type}
                  onChange={(e) => setSelectedTemplate({...selectedTemplate, type: e.target.value as 'New' | 'Reply'})}
                  className="w-full bg-white/10 p-2 rounded-lg"
                  disabled={!isEditing}
                >
                  <option value="New">New</option>
                  <option value="Reply">Reply</option>
                </select>
                <textarea
                  value={selectedTemplate.referenceInformation}
                  onChange={(e) => setSelectedTemplate({...selectedTemplate, referenceInformation: e.target.value})}
                  className="w-full bg-white/10 p-2 rounded-lg h-20"
                  placeholder="Reference Information"
                  disabled={!isEditing}
                />
                <textarea
                  value={selectedTemplate.prompt}
                  onChange={(e) => setSelectedTemplate({...selectedTemplate, prompt: e.target.value})}
                  className="w-full bg-white/10 p-2 rounded-lg h-40"
                  placeholder="Prompt"
                  disabled={!isEditing}
                />
              </div>
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
                  onClick={() => { setSelectedTemplate(null); setIsEditing(false); }}
                >
                  Cancel
                </button>
                {isEditing && (
                  <button
                    type="submit"
                    className="px-4 py-2 bg-xylo-pink text-white rounded-md hover:bg-xylo-pink-light transition-colors"
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default EmailTemplatePage;