import { db, initDatabase } from './initDb';

export const dbService = {
  // User related functions
  addUser: async (user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    signature_block?: string;
    timezone?: string;
    language?: string;
    theme?: string;
    notification_preferences?: object;
  }) => {
    const result = await db.query(
      `INSERT INTO users (id, first_name, last_name, email, signature_block, timezone, language, theme, notification_preferences)
       VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9)
       ON CONFLICT (id) DO UPDATE SET
         first_name = EXCLUDED.first_name,
         last_name = EXCLUDED.last_name,
         email = EXCLUDED.email,
         signature_block = EXCLUDED.signature_block,
         timezone = EXCLUDED.timezone,
         language = EXCLUDED.language,
         theme = EXCLUDED.theme,
         notification_preferences = EXCLUDED.notification_preferences
       RETURNING *`,
      [
        user.id,
        user.first_name,
        user.last_name,
        user.email,
        user.signature_block || null,
        user.timezone || 'UTC',
        user.language || 'en',
        user.theme || 'light',
        JSON.stringify(user.notification_preferences || {})
      ]
    );
    return result.rows[0];
  },
  
  getUserById: async (id: string) => {
    console.log('Attempting to fetch user with ID:', id);
    try {
      const result = await db.query('SELECT * FROM users WHERE id = $1', [id]);
      console.log('User data retrieved:', result.rows[0]);
      return result.rows[0];
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  },

  // Email related functions
  addEmail: async (email: {
    id: string;
    user_id: string;
    sender: string;
    recipient: string;
    subject: string;
    body: string;
    sent_at?: Date;
    is_read?: boolean;
    thread_id?: string;
    parent_email_id?: string;
    is_important?: boolean;
    is_archived?: boolean;
    snooze_until?: Date;
    is_junk?: boolean;
    created_at?: Date;
    last_modified_at?: Date;
    change_key?: string;
    received_at?: Date;
    has_attachments?: boolean;
    internet_message_id?: string;
    importance?: string;
    conversation_index?: string;
    is_delivery_receipt_requested?: boolean;
    is_read_receipt_requested?: boolean;
    is_draft?: boolean;
    web_link?: string;
    inference_classification?: string;
    body_preview?: string;
    body_content_type?: string;
  }) => {
    const result = await db.query(
      `INSERT INTO emails (
        id, user_id, sender, recipient, subject, body, sent_at, is_read, thread_id, parent_email_id,
        is_important, is_archived, snooze_until, is_junk, created_at, last_modified_at, change_key,
        received_at, has_attachments, internet_message_id, importance, conversation_index,
        is_delivery_receipt_requested, is_read_receipt_requested, is_draft, web_link,
        inference_classification, body_preview, body_content_type
      ) VALUES (
        $1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16, $17, $18, $19, $20,
        $21, $22, $23, $24, $25, $26, $27, $28, $29
      ) ON CONFLICT (id) DO UPDATE SET
        sender = EXCLUDED.sender,
        recipient = EXCLUDED.recipient,
        subject = EXCLUDED.subject,
        body = EXCLUDED.body,
        sent_at = EXCLUDED.sent_at,
        is_read = EXCLUDED.is_read,
        thread_id = EXCLUDED.thread_id,
        parent_email_id = EXCLUDED.parent_email_id,
        is_important = EXCLUDED.is_important,
        is_archived = EXCLUDED.is_archived,
        snooze_until = EXCLUDED.snooze_until,
        is_junk = EXCLUDED.is_junk,
        created_at = EXCLUDED.created_at,
        last_modified_at = EXCLUDED.last_modified_at,
        change_key = EXCLUDED.change_key,
        received_at = EXCLUDED.received_at,
        has_attachments = EXCLUDED.has_attachments,
        internet_message_id = EXCLUDED.internet_message_id,
        importance = EXCLUDED.importance,
        conversation_index = EXCLUDED.conversation_index,
        is_delivery_receipt_requested = EXCLUDED.is_delivery_receipt_requested,
        is_read_receipt_requested = EXCLUDED.is_read_receipt_requested,
        is_draft = EXCLUDED.is_draft,
        web_link = EXCLUDED.web_link,
        inference_classification = EXCLUDED.inference_classification,
        body_preview = EXCLUDED.body_preview,
        body_content_type = EXCLUDED.body_content_type
      RETURNING *`,
      [
        email.id, email.user_id, email.sender, email.recipient, email.subject, email.body,
        email.sent_at, email.is_read, email.thread_id, email.parent_email_id, email.is_important,
        email.is_archived, email.snooze_until, email.is_junk, email.created_at, email.last_modified_at,
        email.change_key, email.received_at, email.has_attachments, email.internet_message_id,
        email.importance, email.conversation_index, email.is_delivery_receipt_requested,
        email.is_read_receipt_requested, email.is_draft, email.web_link, email.inference_classification,
        email.body_preview, email.body_content_type
      ]
    );
    return result.rows[0];
  },

  getEmailsByUserId: async (userId: string) => {
    const result = await db.query('SELECT * FROM emails WHERE user_id = $1 ORDER BY sent_at DESC', [userId]);
    return result.rows;
  },

  // Add more functions as needed
};

export { initDatabase };