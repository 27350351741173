import nlp from 'compromise';

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

class SpeechToText {
  recognition: any;
  isListening: boolean;
  transcript: string;
  onTranscriptChange: (transcript: string) => void;
  onStatusChange: (isListening: boolean) => void;

  constructor(onTranscriptChange: (transcript: string) => void, onStatusChange: (isListening: boolean) => void) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.maxAlternatives = 1;
      this.recognition.lang = 'en-US';

      this.recognition.onresult = this.handleResult.bind(this);
      this.recognition.onend = this.handleEnd.bind(this);
      this.recognition.onerror = this.handleError.bind(this);
    } else {
      console.error('Speech recognition not supported');
    }

    this.isListening = false;
    this.transcript = '';
    this.onTranscriptChange = onTranscriptChange;
    this.onStatusChange = onStatusChange;
  }

  start() {
    if (this.recognition) {
      try {
        this.recognition.start();
        this.isListening = true;
        this.onStatusChange(true);
      } catch (error) {
        console.error('Error starting speech recognition:', error);
        this.isListening = false;
        this.onStatusChange(false);
      }
    }
  }

  stop() {
    if (this.recognition) {
      this.recognition.stop();
      this.isListening = false;
      this.onStatusChange(false);
    }
  }

  private handleResult(event: any) {
    let finalTranscript = '';

    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        finalTranscript += event.results[i][0].transcript;
      }
    }

    if (finalTranscript) {
      this.transcript += this.processText(finalTranscript);
      this.onTranscriptChange(this.transcript);
    }
  }

  private handleEnd() {
    this.isListening = false;
    this.onStatusChange(false);
  }

  private handleError(event: any) {
    console.error('Speech recognition error', event.error);
    this.isListening = false;
    this.onStatusChange(false);
    if (event.error === 'not-allowed') {
      alert('Microphone access is required for speech recognition. Please allow microphone access and try again.');
    }
  }

  private processText(text: string): string {
    // Clean up the text
    let cleanedText = text
      .replace(/\.+/g, '.')
      .replace(/\s+/g, ' ')
      .replace(/\s+\./g, '.')
      .trim();

    const doc = nlp(cleanedText);
    
    // Capitalize first letter of each sentence and ensure proper punctuation
    doc.sentences().forEach((sentence: any) => {
      let sentenceText = sentence.text().trim();
      sentenceText = sentenceText.charAt(0).toUpperCase() + sentenceText.slice(1);
      if (!sentenceText.match(/[.!?]$/)) {
        sentenceText += '.';
      }
      sentence.replaceWith(sentenceText);
    });

    // Additional NLP processing
    doc.contractions().expand(); // Expand contractions
    doc.acronyms().strip(); // Format acronyms
    doc.numbers().toNumber(); // Convert number words to digits

    return doc.text();
  }
}

export default SpeechToText;