import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Mic, RefreshCw, Send, StopCircle } from 'react-feather';
import SpeechToText from './speech';

const AiDraftPage: React.FC = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [mode, setMode] = useState<'rewrite' | 'prompt'>('rewrite');
  const [style, setStyle] = useState<'tell' | 'ask' | 'control' | 'emotive'>('tell');
  const [isListening, setIsListening] = useState(false);
  const speechToTextRef = useRef<SpeechToText | null>(null);

  useEffect(() => {
    speechToTextRef.current = new SpeechToText(
      (transcript) => setInputText(transcript),
      (status) => setIsListening(status)
    );
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const handleModeChange = (newMode: 'rewrite' | 'prompt') => {
    setMode(newMode);
  };

  const handleStyleChange = (newStyle: 'tell' | 'ask' | 'control' | 'emotive') => {
    setStyle(newStyle);
  };

  const handleSubmit = () => {
    // TODO: Implement AI processing logic here
    setOutputText(`AI ${mode} output in ${style} style: ${inputText}`);
  };

  const handleSpeechToText = () => {
    if (isListening) {
      speechToTextRef.current?.stop();
    } else {
      speechToTextRef.current?.start();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">AI Draft</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="flex space-x-4 mb-4">
            <button
              className={`px-4 py-2 rounded-full ${mode === 'rewrite' ? 'bg-xylo-pink' : 'bg-white/10'}`}
              onClick={() => handleModeChange('rewrite')}
            >
              Rewrite
            </button>
            <button
              className={`px-4 py-2 rounded-full ${mode === 'prompt' ? 'bg-xylo-pink' : 'bg-white/10'}`}
              onClick={() => handleModeChange('prompt')}
            >
              AI Prompt
            </button>
          </div>
          
          <textarea
            className="w-full h-64 bg-white/10 text-white rounded-lg p-4 resize-none"
            value={inputText}
            onChange={handleInputChange}
            placeholder={mode === 'rewrite' ? "Enter text to rewrite..." : "Enter your AI prompt..."}
          />
          
          <div className="flex justify-between items-center">
            <div className="flex space-x-2">
              {(['tell', 'ask', 'control', 'emotive'] as const).map((s) => (
                <button
                  key={s}
                  className={`px-3 py-1 rounded-full text-sm ${style === s ? 'bg-xylo-pink' : 'bg-white/10'}`}
                  onClick={() => handleStyleChange(s)}
                >
                  {s.charAt(0).toUpperCase() + s.slice(1)}
                </button>
              ))}
            </div>
            
            <div className="flex space-x-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`bg-white/10 p-2 rounded-full ${isListening ? 'text-red-500' : ''}`}
                onClick={handleSpeechToText}
              >
                {isListening ? <StopCircle size={20} /> : <Mic size={20} />}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-xylo-pink text-white px-4 py-2 rounded-full font-medium flex items-center"
                onClick={handleSubmit}
              >
                {mode === 'rewrite' ? <RefreshCw size={20} className="mr-2" /> : <Send size={20} className="mr-2" />}
                {mode === 'rewrite' ? 'Rewrite' : 'Generate'}
              </motion.button>
            </div>
          </div>
        </div>
        
        <div className="bg-white/10 rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Output</h2>
          <div className="bg-white/5 rounded-lg p-4 h-64 overflow-auto">
            {outputText || "AI-generated content will appear here..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiDraftPage;