import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, Edit2, Trash2 } from 'react-feather';

// Import the text file
import annualReviewText from '../mockdata/annualReview.txt';

interface DocumentTemplate {
  id: number;
  name: string;
  description: string;
  instruction: string;
  referenceInformation: string;
  inputParameters: string[];
  dosAndDonts: string;
}

const initialTemplates: DocumentTemplate[] = [
  {
    id: 1,
    name: 'Annual Review',
    description: 'Template to create employee annual review based on company structure',
    instruction: 'Use company template in the reference and combine with following input to create annual review. You can use their communication and personality traits to help craft this annual review to make it more effective. Summarize the overall review and place it in the summary section. Take each of the entered input parameters that match the template section and craft the entire annual review document.',
    referenceInformation: '',
    inputParameters: ["Ratings for each category", "Manager's comments"],
    dosAndDonts: "Do: Provide specific examples for each rating\nDon't: Use subjective language without supporting evidence",
  },
];

const DocumentTemplatePage: React.FC = () => {
  const [templates, setTemplates] = useState<DocumentTemplate[]>(initialTemplates);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<DocumentTemplate | null>(null);

  useEffect(() => {
    // Load the annual review template when the component mounts
    fetch(annualReviewText)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => {
        setTemplates(prevTemplates => 
          prevTemplates.map(template => 
            template.id === 1 ? { ...template, referenceInformation: text } : template
          )
        );
      })
      .catch(error => {
        console.error('Error loading annual review template:', error);
        // You might want to set an error state here to display to the user
      });
  }, []);

  const handleOpenModal = (template: DocumentTemplate | null = null) => {
    setCurrentTemplate(template || {
      id: Date.now(),
      name: '',
      description: '',
      instruction: '',
      referenceInformation: '',
      inputParameters: [],
      dosAndDonts: '',
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentTemplate(null);
  };

  const handleSaveTemplate = (template: DocumentTemplate) => {
    if (template.id) {
      setTemplates(templates.map(t => t.id === template.id ? template : t));
    } else {
      setTemplates([...templates, { ...template, id: Date.now() }]);
    }
    handleCloseModal();
  };

  const handleDeleteTemplate = (id: number) => {
    setTemplates(templates.filter(t => t.id !== id));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Document Templates</h1>
      
      <div className="mb-6 flex justify-between items-center">
        <p className="text-gray-300">
          Create and manage document templates for AI-assisted composition.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-xylo-pink text-white px-4 py-2 rounded-full font-medium flex items-center"
          onClick={() => handleOpenModal()}
        >
          <Plus size={20} className="mr-2" /> Add New Template
        </motion.button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {templates.map(template => (
          <div key={template.id} className="bg-white/10 rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">{template.name}</h3>
            <p className="text-gray-300 mb-4">{template.description}</p>
            <div className="flex justify-end space-x-2">
              <button
                className="text-xylo-pink hover:text-xylo-pink-light"
                onClick={() => handleOpenModal(template)}
              >
                <Edit2 size={18} />
              </button>
              <button
                className="text-red-500 hover:text-red-400"
                onClick={() => handleDeleteTemplate(template.id)}
              >
                <Trash2 size={18} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && currentTemplate && (
        <TemplateModal
          template={currentTemplate}
          onSave={handleSaveTemplate}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

interface TemplateModalProps {
  template: DocumentTemplate;
  onSave: (template: DocumentTemplate) => void;
  onClose: () => void;
}

const TemplateModal: React.FC<TemplateModalProps> = ({ template, onSave, onClose }) => {
  const [formData, setFormData] = useState<DocumentTemplate>(template);
  const [newInputParameter, setNewInputParameter] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddInputParameter = () => {
    if (newInputParameter.trim()) {
      setFormData({
        ...formData,
        inputParameters: [...formData.inputParameters, newInputParameter.trim()]
      });
      setNewInputParameter('');
    }
  };

  const handleRemoveInputParameter = (index: number) => {
    setFormData({
      ...formData,
      inputParameters: formData.inputParameters.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-dark-surface p-8 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto"
      >
        <h2 className="text-2xl font-bold mb-6">
          {template.id ? 'Edit Template' : 'Add New Template'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full bg-white/10 text-white rounded-md px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full bg-white/10 text-white rounded-md px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Instruction</label>
            <textarea
              name="instruction"
              value={formData.instruction}
              onChange={handleChange}
              className="w-full bg-white/10 text-white rounded-md px-3 py-2"
              rows={4}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Reference Information</label>
            <textarea
              name="referenceInformation"
              value={formData.referenceInformation}
              onChange={handleChange}
              className="w-full bg-white/10 text-white rounded-md px-3 py-2"
              rows={6}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Input Parameters</label>
            {formData.inputParameters.map((param, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={param}
                  onChange={(e) => {
                    const newParams = [...formData.inputParameters];
                    newParams[index] = e.target.value;
                    setFormData({ ...formData, inputParameters: newParams });
                  }}
                  className="flex-grow bg-white/10 text-white rounded-md px-3 py-2 mr-2"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveInputParameter(index)}
                  className="text-red-500 hover:text-red-400"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            ))}
            <div className="flex items-center">
              <input
                type="text"
                value={newInputParameter}
                onChange={(e) => setNewInputParameter(e.target.value)}
                className="flex-grow bg-white/10 text-white rounded-md px-3 py-2 mr-2"
                placeholder="New input parameter"
              />
              <button
                type="button"
                onClick={handleAddInputParameter}
                className="bg-xylo-pink text-white px-3 py-2 rounded-md hover:bg-xylo-pink-light transition-colors"
              >
                <Plus size={18} />
              </button>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">Do's and Don'ts</label>
            <textarea
              name="dosAndDonts"
              value={formData.dosAndDonts}
              onChange={handleChange}
              className="w-full bg-white/10 text-white rounded-md px-3 py-2"
              rows={4}
              required
            />
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-xylo-pink text-white rounded-md hover:bg-xylo-pink-light transition-colors"
            >
              Save Template
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default DocumentTemplatePage;