import Papa from 'papaparse';
import mockEmailsData from './mockdata/mockEmails.csv';

interface RawEmail {
  id: string;
  subject: string;
  sender: string;
  summary: string;
  body: string;
  priority: 'important' | 'other';
  read: string;
  requiresResponse: string;
  hasMeetingRequest: string;
  hasActionItems: string;
  responseDate: string;
  [key: string]: string;
}

export interface Email {
  id: string;
  subject: string;
  sender: string;
  summary: string;
  body: string;
  priority: 'important' | 'other';
  read: boolean;
  requiresResponse: boolean;
  hasMeetingRequest: boolean;
  hasActionItems: boolean;
  readingTime: number;
  responseTimeCategory: string;
}

export const fetchEmails = async (): Promise<Email[]> => {
  try {
    const response = await fetch(mockEmailsData);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const csvText = await response.text();
    return new Promise<Email[]>((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors && results.errors.length > 0) {
            reject(`CSV parsing errors: ${results.errors.map(e => e.message).join(', ')}`);
          } else if (results.data.length === 0) {
            reject('No data found in CSV file');
          } else {
            const parsedEmails: Email[] = (results.data as RawEmail[])
              .map((email: RawEmail): Email => ({
                id: email.id,
                subject: email.subject,
                sender: email.sender,
                summary: email.summary,
                body: email.body,
                priority: email.priority as 'important' | 'other',
                read: String(email.read).toLowerCase() === 'true',
                requiresResponse: String(email.requiresResponse).toLowerCase() === 'true',
                hasMeetingRequest: String(email.hasMeetingRequest).toLowerCase() === 'true',
                hasActionItems: String(email.hasActionItems).toLowerCase() === 'true',
                readingTime: Math.ceil(email.body.split(' ').length / 200), // Rough estimate: 200 words per minute
                responseTimeCategory: email.responseDate.toLowerCase(), // Use the category directly from CSV
              }))
              .filter((email: Email) => !email.read); // Only keep unread emails
            resolve(parsedEmails);
          }
        },
        error: (error, file) => {
          reject(`CSV parsing error: ${error.message}`);
        }
      });
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error fetching or parsing emails: ${error.message}`);
    } else {
      throw new Error('An unknown error occurred while fetching or parsing emails');
    }
  }
};