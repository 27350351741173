import axios from 'axios';
import Cookies from 'js-cookie';

let clientId: string;
let redirectUri: string;
clientId = '61ee4e12-50a0-4df2-8191-498e73bb5afa';
redirectUri ="https://devweb.xylo.ai";
/*
if(process.env.REACT_APP_API_URL === 'https://app.dev.teamxylo.com/api') { 
  clientId = '61ee4e12-50a0-4df2-8191-498e73bb5afa';
  redirectUri ="https://app.dev.teamxylo.com/admin_panel/organization";

} else if(process.env.REACT_APP_API_URL === 'https://app.staging.teamxylo.com/api') {
  clientId = '25bf1fa1-04cb-4daf-869d-15848ea908f4';
  redirectUri ="https://app.staging.teamxylo.com/admin_panel/organization";
} else {
  clientId = 'af547de1-ab40-40ad-9819-9e9cd769aa9e';
  redirectUri ="https://app.prod.teamxylo.com/admin_panel/organization";
}
*/
const scope = 'openid profile';

const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?` +
  `redirect_uri=${encodeURIComponent(redirectUri)}&` +
  `response_type=code&` +
  `scope=${encodeURIComponent(scope)}&` +
  `client_id=${clientId}&` +
  'response_mode=query';

export function redirectToMicrosoftAuth() {
  console.log('Redirecting to Microsoft Auth URL:', authUrl);
  window.location.href = authUrl;
}

export async function handleAuthRedirect() {
  try {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    console.log('Code received from redirect:', code);

    if (code) {
      console.log('Exchanging code for tokens.');

      const response = await axios.post(`${process.env.REACT_APP_URL}/dj-rest-auth/microsoft/`, {
        code: code,
        redirect_uri: redirectUri,
        client_id: clientId,
      }, {
        params: {
          callback_url: redirectUri
        }
      });

      console.log('Auth Response:', response.data);
      const access_token = response.data.access;
      const refresh_token = response.data.refresh;

      // Store the tokens in cookies
      Cookies.set('access_token', access_token, { secure: true, sameSite: 'strict' });
      Cookies.set('refresh_token', refresh_token, { secure: true, sameSite: 'strict' });
      
      console.log('Tokens stored in cookies.');
      return true;
    } else {
      console.error('No code found in the URL parameters');
      return false;
    }
  } catch (error) {
    console.error('Error exchanging code for tokens', error);
    return false;
  }
}