import React, { useState } from 'react';
import { Plus, Edit2, Trash2 } from 'react-feather';
import { motion } from 'framer-motion';

interface Phrase {
  id: number;
  words: string;
  partOfSpeech: string;
  description: string;
  caseSensitive: boolean;
  replacementFor: string;
  useCases: string;
  commonMistakes: string;
}

const initialPhrases: Phrase[] = [
  {
    id: 1,
    words: 'Xylo AI',
    partOfSpeech: 'Noun',
    description: 'Our company\'s AI-powered communication assistant',
    caseSensitive: true,
    replacementFor: 'AI assistant',
    useCases: 'Product descriptions, marketing materials',
    commonMistakes: 'Xylo Ai, xylo AI',
  },
  {
    id: 2,
    words: 'leverage',
    partOfSpeech: 'Verb',
    description: 'To use (something) to maximum advantage',
    caseSensitive: false,
    replacementFor: 'use, utilize',
    useCases: 'Business communications, strategy documents',
    commonMistakes: 'leveredge, levrage',
  },
  // Add more initial phrases as needed
];

const TemplatePhrasesPage: React.FC = () => {
  const [phrases, setPhrases] = useState<Phrase[]>(initialPhrases);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newPhrase, setNewPhrase] = useState<Omit<Phrase, 'id'>>({
    words: '',
    partOfSpeech: '',
    description: '',
    caseSensitive: false,
    replacementFor: '',
    useCases: '',
    commonMistakes: '',
  });

  const handleAddPhrase = () => {
    setPhrases([...phrases, { ...newPhrase, id: phrases.length + 1 }]);
    setIsAddModalOpen(false);
    setNewPhrase({
      words: '',
      partOfSpeech: '',
      description: '',
      caseSensitive: false,
      replacementFor: '',
      useCases: '',
      commonMistakes: '',
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Template Phrases</h1>
      
      <div className="mb-6 flex justify-between items-center">
        <p className="text-gray-300">
          Manage custom phrases and terms specific to your brand and communication style.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-xylo-pink text-white px-4 py-2 rounded-full font-medium flex items-center"
          onClick={() => setIsAddModalOpen(true)}
        >
          <Plus size={20} className="mr-2" /> Add New Phrase
        </motion.button>
      </div>

      <div className="bg-white/10 rounded-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-white/5">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Words</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Part of Speech</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody>
            {phrases.map((phrase, index) => (
              <tr key={phrase.id} className={index % 2 === 0 ? 'bg-white/5' : 'bg-white/10'}>
                <td className="px-6 py-4 whitespace-nowrap">{phrase.words}</td>
                <td className="px-6 py-4 whitespace-nowrap">{phrase.partOfSpeech}</td>
                <td className="px-6 py-4">{phrase.description}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="text-xylo-pink hover:text-xylo-pink-light mr-3">
                    <Edit2 size={18} />
                  </button>
                  <button className="text-red-500 hover:text-red-400">
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-dark-surface p-8 rounded-lg w-full max-w-2xl"
          >
            <h2 className="text-2xl font-bold mb-6">Add New Phrase</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Words</label>
                <input
                  type="text"
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  value={newPhrase.words}
                  onChange={(e) => setNewPhrase({ ...newPhrase, words: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Part of Speech</label>
                <input
                  type="text"
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  value={newPhrase.partOfSpeech}
                  onChange={(e) => setNewPhrase({ ...newPhrase, partOfSpeech: e.target.value })}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-300 mb-1">Description</label>
                <textarea
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  rows={3}
                  value={newPhrase.description}
                  onChange={(e) => setNewPhrase({ ...newPhrase, description: e.target.value })}
                />
              </div>
              <div className="col-span-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xylo-pink rounded"
                    checked={newPhrase.caseSensitive}
                    onChange={(e) => setNewPhrase({ ...newPhrase, caseSensitive: e.target.checked })}
                  />
                  <span className="ml-2 text-sm text-gray-300">Case Sensitive</span>
                </label>
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-300 mb-1">Replacement For</label>
                <input
                  type="text"
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  value={newPhrase.replacementFor}
                  onChange={(e) => setNewPhrase({ ...newPhrase, replacementFor: e.target.value })}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-300 mb-1">Use Cases</label>
                <input
                  type="text"
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  value={newPhrase.useCases}
                  onChange={(e) => setNewPhrase({ ...newPhrase, useCases: e.target.value })}
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-300 mb-1">Common Mistakes</label>
                <input
                  type="text"
                  className="w-full bg-white/10 text-white rounded-md px-3 py-2"
                  value={newPhrase.commonMistakes}
                  onChange={(e) => setNewPhrase({ ...newPhrase, commonMistakes: e.target.value })}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
                onClick={() => setIsAddModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-xylo-pink text-white rounded-md hover:bg-xylo-pink-light transition-colors"
                onClick={handleAddPhrase}
              >
                Add Phrase
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default TemplatePhrasesPage;
