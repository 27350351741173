import React from 'react';
import { motion } from 'framer-motion';
import { redirectToMicrosoftAuth } from './microsoft-auth';

const LoginPage: React.FC = () => {
  const handleGoogleLogin = () => {
    // Placeholder for Google login
    console.log('Google login not implemented yet');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg flex flex-col">
      {/* Header */}
      <header className="bg-xylo-purple bg-opacity-90 backdrop-blur-md p-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src="/xylologo.png" alt="Xylo Logo" className="h-10 w-auto" />
        </div>
        <div className="text-white">
          Login to access Xylo
        </div>
      </header>

      {/* Login Content */}
      <div className="flex-grow flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
        >
          <h1 className="text-3xl font-bold text-xylo-purple mb-6">Welcome to Xylo</h1>
          <div className="space-y-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
              onClick={redirectToMicrosoftAuth}
            >
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M11.41 2H2v9.41h9.41V2zM20.59 2h-9.41v9.41h9.41V2zM20.59 11.41h-9.41v9.41h9.41v-9.41zM11.41 11.41H2v9.41h9.41v-9.41z" />
              </svg>
              Login with Microsoft
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
              onClick={handleGoogleLogin}
            >
              <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z" />
              </svg>
              Login with Google
            </motion.button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;