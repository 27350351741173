import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, MessageCircle, BarChart2 } from 'react-feather';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface CampaignPlan {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  platforms: string[];
  status: 'active' | 'scheduled' | 'completed';
}

interface ScheduledMessage {
  id: string;
  campaignId: string;
  content: string;
  platform: string;
  scheduledTime: string;
}

const mockCampaignPlans: CampaignPlan[] = [
  {
    id: '1',
    name: 'Q3 Product Launch',
    startDate: '2024-07-01',
    endDate: '2024-09-30',
    platforms: ['Email', 'LinkedIn', 'Twitter'],
    status: 'active',
  },
  {
    id: '2',
    name: 'Holiday Marketing Campaign',
    startDate: '2024-11-15',
    endDate: '2024-12-31',
    platforms: ['Email', 'Facebook', 'Instagram'],
    status: 'scheduled',
  },
  // Add more mock campaign plans as needed
];

const mockScheduledMessages: ScheduledMessage[] = [
  {
    id: '1',
    campaignId: '1',
    content: 'Exciting news! Our latest product launches next week. Stay tuned for more details.',
    platform: 'LinkedIn',
    scheduledTime: '2024-07-15T10:00:00',
  },
  {
    id: '2',
    campaignId: '1',
    content: 'Join us for a live demo of our new product features this Thursday at 2 PM EST.',
    platform: 'Email',
    scheduledTime: '2024-07-18T14:00:00',
  },
  // Add more mock scheduled messages as needed
];

const mockEngagementData = [
  { date: '2024-07-01', engagement: 45 },
  { date: '2024-07-08', engagement: 52 },
  { date: '2024-07-15', engagement: 61 },
  { date: '2024-07-22', engagement: 58 },
  { date: '2024-07-29', engagement: 65 },
  // Add more mock engagement data as needed
];

const PlannerPage: React.FC = () => {
  const [campaigns] = useState<CampaignPlan[]>(mockCampaignPlans);
  const [scheduledMessages] = useState<ScheduledMessage[]>(mockScheduledMessages);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignPlan | null>(null);

  const handleCampaignSelect = (campaign: CampaignPlan) => {
    setSelectedCampaign(campaign);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">AI Communication Planner</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-1 bg-white/10 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Campaign Plans</h2>
          <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto">
            {campaigns.map(campaign => (
              <motion.div
                key={campaign.id}
                className={`p-4 rounded-lg cursor-pointer ${selectedCampaign?.id === campaign.id ? 'bg-xylo-pink' : 'bg-white/5 hover:bg-white/10'}`}
                onClick={() => handleCampaignSelect(campaign)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <h3 className="font-semibold">{campaign.name}</h3>
                <p className="text-sm text-gray-300">{campaign.startDate} - {campaign.endDate}</p>
                <div className="flex mt-2">
                  {campaign.platforms.map(platform => (
                    <span key={platform} className="text-xs bg-white/20 rounded-full px-2 py-1 mr-2">
                      {platform}
                    </span>
                  ))}
                </div>
                <span className={`text-xs ${
                  campaign.status === 'active' ? 'text-green-400' :
                  campaign.status === 'scheduled' ? 'text-yellow-400' :
                  'text-gray-400'
                } mt-2 inline-block`}>
                  {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                </span>
              </motion.div>
            ))}
          </div>
        </div>
        
        <div className="lg:col-span-2 space-y-6">
          {selectedCampaign ? (
            <>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Campaign Overview: {selectedCampaign.name}</h2>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-400">Start Date</p>
                    <p className="font-semibold">{selectedCampaign.startDate}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">End Date</p>
                    <p className="font-semibold">{selectedCampaign.endDate}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Platforms</p>
                    <p className="font-semibold">{selectedCampaign.platforms.join(', ')}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Status</p>
                    <p className="font-semibold">{selectedCampaign.status}</p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Scheduled Messages</h2>
                <div className="space-y-4">
                  {scheduledMessages
                    .filter(message => message.campaignId === selectedCampaign.id)
                    .map(message => (
                      <div key={message.id} className="bg-white/5 p-4 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div className="flex-1">
                            <p className="font-semibold">{message.platform}</p>
                            <p className="text-sm text-gray-300">{message.content}</p>
                          </div>
                          <div className="flex items-center text-sm text-gray-400">
                            <Clock size={14} className="mr-1" />
                            {new Date(message.scheduledTime).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-white/10 p-6 rounded-lg"
              >
                <h2 className="text-2xl font-semibold mb-4">Campaign Performance</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={mockEngagementData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis dataKey="date" stroke="#888" />
                    <YAxis stroke="#888" />
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#333', border: 'none' }}
                      labelStyle={{ color: '#fff' }}
                    />
                    <Line type="monotone" dataKey="engagement" stroke="#ED5C5E" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </motion.div>
            </>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-400">
              <Calendar size={48} className="mr-4" />
              <p>Select a campaign to view details</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
        <motion.div
          className="bg-white/10 p-6 rounded-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Calendar size={20} className="mr-2" />
            Upcoming Posts
          </h2>
          <p className="text-gray-300">5 posts scheduled for next week</p>
        </motion.div>

        <motion.div
          className="bg-white/10 p-6 rounded-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <MessageCircle size={20} className="mr-2" />
            Content Suggestions
          </h2>
          <p className="text-gray-300">3 new AI-generated content ideas available</p>
        </motion.div>

        <motion.div
          className="bg-white/10 p-6 rounded-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <BarChart2 size={20} className="mr-2" />
            Performance Insights
          </h2>
          <p className="text-gray-300">Engagement up 12% from last month</p>
        </motion.div>
      </div>
    </div>
  );
};

export default PlannerPage;
